import React ,{useState} from 'react';
import '../card.css'
import image from '../../src/assets/images/softwaredevelopment.png'
import image1 from '../../src/assets/images/service2.png'
import image2 from '../../src/assets/images/itservices.png'
import image3 from '../../src/assets/images/oracle.png'
import image4 from '../../src/assets/images/ai.png'
import image5 from '../../src/assets/images/sap.png'


const Service = () => {
 
  return (
    
    <div className="container mt-5" id="Services">
		<div className="text-center">
		  <h1 className="who" alt="Services">Services</h1>
		  <h3 className="ser-head">What We Offer for You</h3>
  
  
		</div>
    
        <div className="row ">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="Software Development">Software Development </h1>
				{/* <p class="animate slide-left pop delay-5" style={{color: '#2e3192' , marginBottom : '2.5rem'}}>Kingdom: <em>Plantae</em></p> */}
			</div>
			<div class="image-content1 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			<div class="text">
				<p><img class="inset" src={image} alt="Software Development" /> <h4><a className="text-decoration-none title">Your Expert Software Development Partner.</a></h4>
    <p  className="title1">At Round Point Tech, we specialize in providing expert software development services tailored to the unique needs of businesses. Our dedicated team of professionals is committed to delivering cutting-edge software solutions that streamline operations and enhance productivity.</p>
    <h4><a className="text-decoration-none title">We Offer a Wide Range of Software Services</a></h4>
    <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
        <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
            Custom Software Development
        </li>
        <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
            Web Application Development
        </li>
        <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
            Mobile App Development
        </li>
        <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
            Cloud Solutions
        </li>
        <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
            Quality Assurance and Testing
        </li>
        <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
            Ongoing Support and Maintenance
        </li></ul>
				</p>
			</div>
	</div>
    </div>
    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="Quality Assurance"> Quality Assurance</h1>
				{/* <p class="animate slide-left pop delay-5" style={{color: 'white' , marginBottom : '2.5rem'}}>Kingdom: <em>Plantae</em></p> */}
			</div>
			<div class="image-content2 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			<div class="text">
				<p><img class="inset" src={image1} alt="Quality Assurance" />
				<h4><a className="text-decoration-none title">Quality Assurance Excellence</a></h4>
  <p  className="title1">At Round Point, we excel in delivering top-tier Quality Assurance services that ensure the highest standards of software quality and reliability. Our comprehensive approach includes rigorous testing, meticulous bug tracking, and continuous improvement to enhance your product's quality throughout its lifecycle.</p>
  <h4><a className="text-decoration-none title">We Offer a Range of QA Solutions</a></h4>
  <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      Comprehensive Testing
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      Thorough Bug Tracking
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      Continuous Improvement
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      Test Automation
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      Performance Testing
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      Security Testing
    </li>
  </ul></p>
			</div>
	</div>
    </div>
    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="Network Infrastructure">Network Infrastructure</h1>
				{/* <p class="animate slide-left pop delay-5" style={{color: 'white' , marginBottom : '2.5rem'}}>Kingdom: <em>Plantae</em></p> */}
			</div>
			<div class="image-content3 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			<div class="text">
				<p><img class="inset" src={image2} alt="Network Infrastructure" />
				<h4><a className="text-decoration-none title">Elevating Network Infrastructure Solutions.</a></h4>
  <p  className="title1"> At Round Point, we specialize in providing cutting-edge network infrastructure solutions that optimize your connectivity and enhance the performance of your digital ecosystem. Our expertise spans efficient deployment, robust monitoring, effective logging, scalable microservices, automation, and continuous delivery.</p>
  <h4><a className="text-decoration-none title">We offer a variety of Network Infrastructure solutions:</a></h4>
  <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    Efficient Deployment
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary "></i>Robust Monitoring</li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    Effective Logging
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    Scalable Microservices
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    Automation
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Continuous Delivery</li>
  </ul></p>
			</div>
	</div>
    </div>

	<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="Staff Augmentation">Staff Augmentation</h1>
				{/* <p class="animate slide-left pop delay-5" style={{color: 'white' , marginBottom : '2.5rem'}}>Kingdom: <em>Plantae</em></p> */}
			</div>
			<div class="image-content4 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			<div class="text">
				<p><img class="inset" src={image3} alt="Staff Augmentation" />
				<h4><a className="text-decoration-none title">Elevating Your Team with Staff Augmentation.</a></h4>
  <p  className="title1"> At Round Point, we excel in providing top-notch staff augmentation services to enhance your team's capabilities and efficiency. Our skilled professionals seamlessly integrate with your projects, offering expertise in various domains.</p>
  <h4><a className="text-decoration-none title">Our Staff Augmentation Services Include:</a></h4>
  <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Skilled Professionals</li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Domain Expertise</li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Seamless Integration</li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Enhanced Efficiency</li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Flexible Solutions</li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Optimized Team</li>
  </ul>
				</p>
			</div>
	</div>
    </div>

	<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="Artificial Intelligence">Artificial Intelligence</h1>
				{/* <p class="animate slide-left pop delay-5" style={{color: 'white' , marginBottom : '2.5rem'}}>Artificial Intelligence <em>Plantae</em></p> */}
			</div>
			<div class="image-content5 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			<div class="text">
				<p><img class="inset" src={image4} alt="Artificial Intelligence" />
				<h4><a className="text-decoration-none title">Your AI Solutions Partner.</a></h4>
  <p  className="title1"> At Round Point, we specialize in delivering cutting-edge AI solutions that transform your business processes and decision-making. We harness the power of artificial intelligence to enhance various aspects of your operations.</p>
  <h4><a className="text-decoration-none title">We offer a range of AI solutions, including:</a></h4>
  <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      AI-driven Automation
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary "></i>Advanced Data Analytics</li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      Natural Language Processing
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      Machine Learning Solutions
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
      AI-powered Decision Support
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>Custom AI Development</li>
  </ul></p>
			</div>
	</div>
    </div>

	<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="Maintenance & Support">Maintenance & Support</h1>
				{/* <p class="animate slide-left pop delay-5" style={{color: 'white' , marginBottom : '2.5rem'}}>Kingdom: <em>Plantae</em></p> */}
			</div>
			<div class="image-content6 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			<div class="text">
				<p><img class="inset" src={image5} alt="Maintenance & Support" />
				<h4><a className="text-decoration-none title">Your Maintenance & Support Partner.</a></h4>
  <p className="title1"> At Round Point, we specialize in providing exceptional maintenance and support services to ensure the smooth operation and longevity of your software and technology infrastructure. We offer comprehensive solutions to address your ongoing needs.</p>
  <h4><a className="text-decoration-none title">We offer a range of Maintenance & Support solutions, including:</a></h4>
  <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    Regular Maintenance
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    24/7 Technical Support
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    System Upgrades
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    Security Patching
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    Performance Optimization
    </li>
    <li className="py-1 title2"><i className="fa fa-check-circle me-2 text-primary"></i>
    Customized Support Plans
    </li>
  </ul></p>
			</div>
	</div>
    </div>

</div>
    </div>
  )
};

export default Service;

import React from 'react'
import image1 from '../../src/assets/images/career.png'
import { Link  } from 'react-router-dom'


const Career = () => {
  
  return (
    <div>
        <div className="container mt-5" id="Career">
            <div className="text-center">
        <h1 className="who"  alt ="Career">Career</h1>
        <h3 className="ser-head">Explore the Benefits with Us</h3>
      </div>
    <div className="row ">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="cyber-about-img text-center mb-30 mb-lg-0">
                <img src={image1} alt="VR" className="img-fluid"/>
                
            </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
            <h6 className="who">About Career</h6>
            <p style={{textAlign:"justify"}}>Imagine your career at Round Point, a place recognized among the world's finest workplaces. Here, you'll be part of dynamic teams and alongside individuals who encourage, motivate, and drive you to achieve extraordinary success.</p>
            <Link to="/Careerjobs " target="_top">
            <div class="d-flex1 justify-content-center1 align-items-center1  ">
            <button class="pushable">
  <span class="shadow"></span>
  <span class="edge"></span>
  <span class="front1">
    Apply Now
  </span>
</button>
</div>
</Link>

</div>

</div>
</div>

</div>

  )
}

export default Career
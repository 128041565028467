import React from "react";
import './App.css';
import Signinroutes from "./Routes/signinroutes";



const App = () => {
  return (
   <div>
 <Signinroutes/>
    
 </div>
  );
};

export default App;

import React, { useEffect,useRef } from 'react';
import image from '../../src/assets/images/header1.png';


const Section = () => {
  var words = ['We help you to grow your business', 'We provide best enterprise solutions'],
    part,
    i = 0,
    offset = 0,
    len = words.length,
    forwards = true,
    skip_count = 0,
    skip_delay = 15,
    speed = 170;

  var wordflick = function () {
    setInterval(function () {
      if (forwards) {
        if (offset >= words[i].length) {
          ++skip_count;
          if (skip_count === skip_delay) {
            forwards = false;
            skip_count = 0;
          }
        }
      } else {
        if (offset === 0) {
          forwards = true;
          i++;
          offset = 0;
          if (i >= len) {
            i = 0;
          }
        }
      }
      part = words[i].substr(0, offset);
      if (skip_count === 0) {
        if (forwards) {
          offset++;
        } else {
          offset--;
        }
      }
      document.querySelector('.word').textContent = part;
    }, speed);
  };

  useEffect(() => {
    wordflick();
  }, []);

  
  return (
    <div>
      
      <div>
        
        <section id="home" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-delay="100">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <h1 class="mt-5" alt="Welcome to Round Point">Welcome to Round Point</h1>
              <h2 className="banner-text">
                Round Point represents a prominent consulting and integration firm that leverages its expertise to provide tailored solutions aligning with industry standards.
              </h2>

              

              <div className="d-flex">
                <a href="#About" className="btn-get-started scrollto">
                  Learn More
                </a>
                
              </div>
              </div>

      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img src={image} alt="Home Section" className="banner-img2 rotating-img pulsating-img mt-5 img-fluid"/>
</div>

  </div>
  
  <div className="word "></div>

  </div>
</section>


    </div>
    </div>
  );
};

export default Section;








import React from 'react'
import { Route,Routes } from "react-router-dom";
import Home from '../Home/home';
import Careerjobs from '../careerjobs';
import Pagenotfound from '../pagenotfound';



const Signinroutes = () => {
    return (
      <div>
  
  <Routes>
        <Route path="/" element= {<Home/>}/>
        <Route path="/Home" element= {<Home/>}/>
        <Route path="/Careerjobs" element= {<Careerjobs/>}/>
        <Route path="/*" element={<Pagenotfound/>}/>
        
        
        
  </Routes>
      </div>
    )
  }
  
  export default Signinroutes